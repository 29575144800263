// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_k3";
export var caseStudyBackground__lineColor = "bN_k0";
export var caseStudyHead = "bN_kW";
export var caseStudyHead__imageWrapper = "bN_kX";
export var caseStudyProjectsSection = "bN_k4";
export var caseStudyQuote__bgLight = "bN_kY";
export var caseStudyQuote__bgRing = "bN_kZ";
export var caseStudyVideoReview = "bN_l1";
export var caseStudyVideoReview__bgRing = "bN_l2";
export var caseStudyVideo__ring = "bN_k2";
export var caseStudy__bgDark = "bN_kV";
export var caseStudy__bgLight = "bN_kT";
export var caseStudy__bgLightReverse = "bN_l0";